import {createStore, combineReducers, applyMiddleware, compose} from "redux"
import thunk from "redux-thunk"
import {createLogger} from "redux-logger"

import templateReducers from "./reducers/template"
import authReducers from "./reducers/auth"
import investorReducers from "./reducers/investors"
import subadvisorReducers from "./reducers/subadvisors"
import filterSubadvisorReducers from "./reducers/filterSubadvisors"
import productReducers from "./reducers/products"
import managerInvestmentReducers from "./reducers/managerInvestments"
import filterInvestorReducers from "./reducers/filterInvestors"
import orderReducers from "./reducers/orders"
import outstandingOrderReducers from "./reducers/outstandingOrders"
import subscriptionReducers from "./reducers/subscriptions"
import redemptionReducers from "./reducers/redemptions"
import orderComplete from "./reducers/ordersComplete"
import dashboard from "./reducers/dashboard"
import form from "./reducers/form"
import signature from "./reducers/signature"
import portfolio from "./reducers/portfolio"
import switching from "./reducers/switch"
import admins from "./reducers/admins"
import parentCode from "./reducers/parentCode"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  const logger = createLogger({collapsed: true})
  const middlewares =
    process.env.NODE_ENV === "production" ? [thunk] : [thunk, logger]

  const store = createStore(
    combineReducers({
      auth: authReducers,
      template: templateReducers,
      investors: investorReducers,
      subadvisors: subadvisorReducers,
      filterSubadvisors: filterSubadvisorReducers,
      filterInvestors: filterInvestorReducers,
      products: productReducers,
      managerInvestments: managerInvestmentReducers,
      orders: orderReducers,
      outstandingOrders: outstandingOrderReducers,
      subscriptions: subscriptionReducers,
      redemptions: redemptionReducers,
      orderComplete,
      dashboard,
      form,
      signature,
      portfolio,
      switching,
      admins,
      parentCode
    }),
    composeEnhancers(applyMiddleware(...middlewares))
  )

  return store
}

import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"
import {Provider} from "react-redux"
import {createTheme, ThemeProvider} from "@material-ui/core/styles"
import AppRouter from "./routers/AppRouter"
import * as serviceWorker from "./serviceWorker"
import configureStore from "./store/configureStore"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "./assets/scss/style.scss"

const store = configureStore()

Sentry.init({dsn: process.env.REACT_APP_SENTRY})

const theme = createTheme({
  palette: {
    primary: {
      main: "#bf8e8a",
      light: "rgba(191, 142, 138, 0.2)"
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    subtitle1: {
      fontWeight: 900
    },
    subtitle2: {
      fontWeight: 900
    },
    button: {
      textTransform: "none",
      fontWeight: 700
    }
  },
  overrides: {
    MuiButton: {
      outlined: {
        color: "#7d8188"
      },
      containedPrimary: {
        backgroundColor: "rgba(191, 142, 138, 0.2)",
        color: "#bf8e8a",
        boxShadow: "none"
      }
    }
  }
})

const app = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

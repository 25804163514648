export const GET_DRAFT_INVESTOR_REQUEST = "GET_DRAFT_INVESTOR_REQUEST"
export const GET_DRAFT_INVESTOR_SUCCESS = "GET_DRAFT_INVESTOR_SUCCESS"
export const GET_DRAFT_INVESTOR_FAILURE = "GET_DRAFT_INVESTOR_FAILURE"
export const GET_INVESTOR_REQUEST = "GET_INVESTOR_REQUEST"
export const GET_INVESTOR_SUCCESS = "GET_INVESTOR_SUCCESS"
export const GET_INVESTOR_FAILURE = "GET_INVESTOR_FAILURE"
export const GET_INVESTOR_OPTION_SUCCESS = "GET_INVESTOR_OPTION_SUCCESS"
export const GET_INVESTOR_DETAIL_SUCCESS = "GET_INVESTOR_DETAIL_SUCCESS"
export const GET_DETAIL_INVESTOR = "GET_DETAIL_INVESTOR"
export const GET_DETAIL_INVESTOR_DRAFT = "GET_DETAIL_INVESTOR_DRAFT"

import {
  GET_DRAFT_INVESTOR_REQUEST,
  GET_DRAFT_INVESTOR_SUCCESS,
  GET_DRAFT_INVESTOR_FAILURE,
  GET_INVESTOR_REQUEST,
  GET_INVESTOR_SUCCESS,
  GET_INVESTOR_FAILURE,
  GET_INVESTOR_OPTION_SUCCESS,
  GET_INVESTOR_DETAIL_SUCCESS,
  GET_DETAIL_INVESTOR,
  GET_DETAIL_INVESTOR_DRAFT
} from "../actionTypes/investors"

import {START_FORM_REGISTER} from "../actionTypes/form"

const initialState = {
  data: {
    investors: [],
    draftInvestors: [],
    option_investors: [],
    detail: {},
    investorsDetail: {},
    investorsDraftDetail: {}
  },
  meta: {
    status: "",
    isLoading: true,
    total: 0
  },
  sorter: {
    investors: {
      type: "DESC",
      sortBy: "created_at",
      dataType: "date"
    },
    draftInvestors: {
      type: "DESC",
      sortBy: "created_at",
      dataType: "date"
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_FORM_REGISTER:
      return {
        ...state,
        data: {
          ...state.data,
          investorsDraftDetail: initialState.data.investorsDraftDetail
        }
      }
    case GET_DRAFT_INVESTOR_REQUEST || GET_INVESTOR_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_INVESTOR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          investors: action.payload.investors
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false,
          total: action.payload.total
        }
      }
    case GET_INVESTOR_OPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          option_investors: action.payload
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_INVESTOR_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          detail: action.payload
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_DRAFT_INVESTOR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          draftInvestors: action.payload.investors
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false,
          total: action.payload.total
        }
      }
    case GET_DRAFT_INVESTOR_FAILURE || GET_INVESTOR_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case GET_DETAIL_INVESTOR:
      return {
        data: {
          ...state.data,
          investorsDetail: action.payload
        },
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_DETAIL_INVESTOR_DRAFT:
      return {
        data: {
          ...state.data,
          investorsDraftDetail: action.payload
        },
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }

    default:
      return state
  }
}
